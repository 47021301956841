import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Axios from 'axios';
import storage from '../../helpers/storage'
import loader from '../../helpers/loader'

import VaceContext from './vaceContext';
import VaceReducer from './vaceReducer';

import {
    SET_LOADING,
    UNSET_LOADING,
    GET_BUSINESS,
    GET_WALLET,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    GET_BILLERS,
    SET_WALLET,
    GET_TRANSACTIONS,
    SET_PAGINATION,
    SET_TOTAL,
    SET_COUNT,
    GET_BENEFICIARIES,
    GET_BUSINESS_BANKS,
    SET_SEARCH,
    GET_BILL_CATEGORIES,
    GET_MOBILE_PLANS,
    GET_PRODUCTS,
    GET_PRODUCT,
    SET_SPLITS,
    GET_PAYMENT_LINKS,
    GET_PAYMENT_LINK,
    GET_VACE_OVERVIEW,
    SET_RESPONSE,
    SET_BANK,
    GET_TRANSACTION,
    GET_REFUNDS,
    GET_CHARGEBACK,
    GET_VACE_GRAPH,
    SET_APIDONE,
    SET_INVOICE_ITEMS,
    GET_INVOICES,
    GET_INVOICE,
    GET_SUBACCOUNT,
    GET_SUBACCOUNTS,
    GET_WEBHOOK,
    GET_BUSINESS_SETTINGS,
    GET_BILL_PRODUCTS,
    SET_PAYMENT_METHODS,
    SET_CURRENCY,
    SET_FILTER_OPTIONS,
    SET_ANALYTICS,
    GET_ANALYTICS,
    GET_SETTLEMENT,
    GET_SETTLEMENTS
} from '../types'
import { ICheckoutOption, IFilterOptions, IInvoiceItem, IListQuery, IProductSplit, IResponse, ISearchProps, IVaceOverview, IYearChartData } from '../../utils/types';
import { ValidateBillerDTO } from '../../dtos/vas.dto';
import { SetGraphDataDTO, SetPieDataDTO, SetYearChartDTO } from '../../dtos/chart.dto';
import body from '../../helpers/body';
import { CurrencyType, FeatureType } from '../../utils/enums';

const VaceState = (props: any) => {

    const cookie = new Cookies();

    const exp = new Date(
        Date.now() + 70 * 24 * 60 * 60 * 1000
    )

    const navigate = useNavigate()
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        overview: {},
        analytics: {},
        filter: {},
        graph: {},
        business: {},
        settings: {},
        wallet: {},
        webhook: {},
        accounts: [],
        billers: [],
        biller: {},
        currency: CurrencyType.NGN,
        dataPlans: [],
        splits: [],
        bills: [],
        billProducts: [],
        account: {},
        beneficiaries: [],
        beneficiary: {},
        chargebacks: [],
        chargeback: {},
        refunds: [],
        refund: {},
        banks: [],
        bank: {},
        invoices: [],
        invoice: {},
        invoiceItems: [],
        products: [],
        product: {},
        paymentLinks: [],
        paymentLink: {},
        subaccounts: [],
        subaccount: {},
        settlements: [],
        settlement: {},
        transactions: [],
        transaction: {},
        paymentMethods: [],
        total: 0,
        count: 0,
        pagination: {},
        progress: 0,
        loading: false,
        apiDone: false,
        response: {},
        search: {
            error: false,
            message: '',
            data: [],
            list: []
        },
    }

    const [state, dispatch] = useReducer(VaceReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        localStorage.clear();
        cookie.remove('token');
        cookie.remove('userType');

        navigate('/login');
        // wait for logout API
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    /**
     * @name getOverview
     */
    const getOverview = async () => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/users/overview`, storage.getConfigWithBearer())
            .then((resp) => {

                const overview: IVaceOverview = resp.data.data;

                dispatch({
                    type: GET_VACE_OVERVIEW,
                    payload: resp.data.data
                });

                setGraphData({ overview });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get overview ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get overview ${err}`)

                }

            })

    }

    /**
     * @name getGraphData
     */
    const getGraphData = async (date?: string) => {

        let data = {
            date: date ? date : ''
        }

        setLoading()
        await Axios.post(`${process.env.REACT_APP_VACE_URL}/users/graph`, { ...data }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_VACE_GRAPH,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get graph ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get graph ${err}`)

                }

            })

    }

    /**
     * @name getBusiness
     * @param id 
     */
    const getBusiness = async (id: string): Promise<any> => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESS,
                    payload: resp.data.data
                });

                if (resp.data.data.wallet) {
                    dispatch({
                        type: GET_WALLET,
                        payload: resp.data.data.wallet
                    });
                }

                if (resp.data.data.accounts) {

                    dispatch({
                        type: GET_ACCOUNTS,
                        payload: resp.data.data.accounts
                    });

                    dispatch({
                        type: GET_ACCOUNT,
                        payload: resp.data.data.accounts[0]
                    });

                }

                if (resp.data.data.settings) {
                    dispatch({
                        type: GET_BUSINESS_SETTINGS,
                        payload: resp.data.data.settings
                    });
                }

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business ${err}`)

                }

            })

    }

    /**
     * @name getWebhookData
     * @param id 
     */
    const getWebhookData = async (id?: string): Promise<any> => {

        let userId = id ? id : storage.getUserID()
        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/webhook/${userId}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_WEBHOOK,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business webhook ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business webhook ${err}`)

                }

            })

    }

    /**
     * @name getWallet
     * @param id 
     */
    const getWallet = async (id: string): Promise<any> => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/wallet/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_WALLET,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet details ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet details ${err}`)

                }

            })

    }

    /**
     * @name getAccounts
     * @param id 
     */
    const getAccounts = async (id: string): Promise<any> => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/accounts/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_ACCOUNTS,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet details ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet details ${err}`)

                }

            })

    }

    /**
     * @name getTransactions
     * @param id 
     * @param limit 
     * @param page 
     */
    const getTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;
        let userId: string = id ? id : storage.getUserID();

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/transactions/${userId}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get transactions ${err}`)

                }

            })

    }

    /**
     * @name getTransaction
     * @param id 
     */
    const getTransaction = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/transactions/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTION,
                    payload: resp.data.data
                });

                if (resp.data.data.refunds && resp.data.data.refunds.length > 0) {
                    dispatch({
                        type: GET_REFUNDS,
                        payload: resp.data.data.refunds
                    })
                }

                if (resp.data.data.chargeback && resp.data.data.chargeback) {
                    dispatch({
                        type: GET_CHARGEBACK,
                        payload: resp.data.data.chargeback
                    })
                }

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get transaction ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get transaction ${err}`)

                }

            })

    }

    /**
     * @name verifyTransaction
     * @param ref 
     */
    const verifyTransaction = async (ref: string) => {

        setLoading();

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/transactions/verify`, { reference: ref }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTION,
                    payload: resp.data.data
                });

                if (resp.data.data.business) {
                    dispatch({
                        type: GET_BUSINESS,
                        payload: resp.data.data.business
                    })
                }

                if (resp.data.data.payment) {
                    dispatch({
                        type: GET_PAYMENT_LINK,
                        payload: resp.data.data.payment
                    })
                }

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 404) {
                    message = 'Transaction does not exist'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                });

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not verify transaction ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not verify transaction ${err}`)

                }

                unsetLoading()

            })

    }

    /**
     * @name getWalletTransactions
     * @param id 
     * @param data 
     */
    const getWalletTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/wallet-transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet transactions ${err}`)

                }

            })

    }

    /**
     * @name getLinkTransactions
     * @param id 
     * @param data 
     */
    const getLinkTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet transactions ${err}`)

                }

            })

    }

    /**
     * @name getBeneficiaries
     * @param id 
     * @param limit 
     * @param page 
     */
    const getBeneficiaries = async (id: string, limit: number, page: number) => {

        const q = `limit=${limit.toString()}&page=${page.toString()}&order=desc`;
        let userId: string = id ? id : storage.getUserID();

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/beneficiaries/${userId}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BENEFICIARIES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get beneficiaries ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get beneficiaries ${err}`)

                }

            })

    }

    /**
     * @name getBanks
     * @param id 
     */
    const getBanks = async (id: string) => {

        let userId: string = id ? id : storage.getUserID();
        const q = `limit=9999&page=1&order=desc`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/banks/${userId}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESS_BANKS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get banks ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get banks ${err}`)

                }

            })

    }

    /** 
     * @name getBillers
     * @description get all billers information
     */
    const getBillers = async () => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/vas/bill-categories`, storage.getConfigWithBearer())
            .then((resp) => {
                dispatch({
                    type: GET_BILLERS,
                    payload: resp.data.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    /** 
     * @name getMobileDataPlans
     * @description get mobile data plans
     */
    const getMobileDataPlans = async (data: { network?: string, phone?: string }) => {

        setLoading();

        let payload: any = {};

        if (data.network) {
            payload.networkName = data.network
        }

        if (data.phone) {
            payload.phoneNumber = data.phone
        }

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/vas/mobile-data-plans`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {
                dispatch({
                    type: GET_MOBILE_PLANS,
                    payload: resp.data.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    /** 
     * @name getBillCategories
     * @description get all billers sub categories information
     */
    const getBillCategories = async (id: number) => {

        setLoading();
        setAPIDone(false)

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/vas/bill-sub-categories`, { categoryId: id }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BILL_CATEGORIES,
                    payload: resp.data.data
                })

                setAPIDone(true)

            })
            .catch((err) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get billers subcategories ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get billers subcategories ${err}`)

                }

                setAPIDone(true)

            })
    }

    const getBillProducts = async (category: string) => {

        setLoading();
        setAPIDone(false)

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/vas/bill-products`, { subCategory: category }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BILL_PRODUCTS,
                    payload: resp.data.data
                })

                setAPIDone(true)

            })
            .catch((err) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get bill products ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get bill products ${err}`)

                }

                setAPIDone(true)

            })
    }

    /**
     * @name getProducts
     * @param id 
     * @param limit 
     * @param page 
     */
    const getProducts = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/products/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PRODUCTS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business products ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business products ${err}`)

                }

            })

    }

    /**
     * @name getProduct
     * @param id 
     */
    const getProduct = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/products/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PRODUCT,
                    payload: resp.data.data
                });

                if (resp.data.data.splits.length > 0) {

                    const allSplits = resp.data.data.splits.map((x: any) => {

                        let split: IProductSplit = {
                            type: x.type,
                            value: x.value,
                            label: x.label,
                            flat: x.type === 'flat' ? true : false,
                            percentage: x.type === 'percentage' ? true : false,
                            dest: x.destination,
                            bank: {
                                accountName: x.bank.accountName,
                                accountNo: x.bank.accountNo,
                                bankCode: x.bank.bankCode,
                                bankName: x.bank.bankName
                            }
                        }

                        return split;

                    })

                    setSplits(allSplits);

                }

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get product ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get product ${err}`)

                }

            })

    }

    const getProductTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/products/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice transactions ${err}`)

                }

            })

    }

    const getPaymentLinks = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/payment-links/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PAYMENT_LINKS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business payment links ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business payment links ${err}`)

                }

            })

    }

    const getPaymentLink = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PAYMENT_LINK,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment link ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment link ${err}`)

                }

            })

    }

    const getPaymentLinkByLabel = async (label: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/url/${label}`, storage.getConfigWithBearer())
            .then((resp) => {

                const { invoice, product } = resp.data.data;

                if ((resp.data.data.feature === FeatureType.INVOICE && !invoice.isEnabled) || (resp.data.data.feature === FeatureType.PRODUCT && !product.isEnabled)) {

                    setResponse({
                        data: null,
                        message: `This payment link is not properly confgured. ${body.captialize(resp.data.data.feature)} is not enabled.`,
                        status: 400,
                        errors: [],
                        error: true
                    })

                } else if (resp.data.data.feature === FeatureType.INVOICE && invoice.status === 'paid') {

                    setResponse({
                        data: null,
                        message: `Payment link is attached to invoice #${invoice.number} and invoice is already paid`,
                        status: 400,
                        errors: [],
                        error: true
                    })

                } else {

                    dispatch({
                        type: GET_PAYMENT_LINK,
                        payload: resp.data.data
                    });

                    if(resp.data.data.business){
                        dispatch({
                            type: GET_BUSINESS,
                            payload: resp.data.data.business
                        });
                    }

                    if(resp.data.data.invoice){
                        dispatch({
                            type: GET_INVOICE,
                            payload: resp.data.data.invoice
                        })
                    }

                    if(resp.data.data.product){
                        dispatch({
                            type: GET_PRODUCT,
                            payload: resp.data.data.product
                        })
                    }

                }

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This payment details is currently deactivated. Contact business to enable you to pay.'
                } else if (status === 403) {
                    message = 'This payment configuration is incorrect. Contact business to enable you to pay'
                } if (status === 404) {
                    message = 'This payment details does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment link ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment link ${err}`)

                }

                unsetLoading()

            })

    }

    const getSubaccounts = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/subaccounts/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SUBACCOUNTS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business subaccounts ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business subaccounts ${err}`)

                }

            })

    }

    const getSubaccount = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/subaccounts/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SUBACCOUNT,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get subaccount ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get subaccount ${err}`)

                }

            })

    }

    const getSubaccountByCode = async (code: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/subaccounts/by-code/${code}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SUBACCOUNT,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get subaccount ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get subaccount ${err}`)

                }

                unsetLoading()

            })

    }

    /**
     * @name getSettlements
     * @param id 
     * @param data 
     */
    const getSettlements = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/settlements/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SETTLEMENTS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status && status === 401) {

                    logout();

                } else if (data || errors || message) {

                    console.log(`Error! Could not get settlements ${errors.length > 0 ? errors[0] : message}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlements ${err}`)

                }


            })

    }

    /**
     * @name getSettlement
     * @param id 
     */
    const getSettlement = async (id: string) => {


        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/get-settlement/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SETTLEMENT,
                    payload: resp.data.data
                });
            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlement ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlement ${err}`)

                }

            })

    }

    /**
     * @name getSettlementAnalytics
     * @param code 
     * @param id 
     */
    const getSettlementAnalytics = async (id: string, code: string) => {

        let payload = {
            code: code,
            businessId: id
        }

        setLoading();

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/businesses/settlement-analytics`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_ANALYTICS,
                    payload: resp.data.data
                });
            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlement analytics ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlement analytics ${err}`)

                }

            })

    }

    /**
     * @name getSettlementTransactions
     * @param param 
     * @param data 
     */
    const getSettlementTransactions = async (param: { id: string, settlementId?: string, code?: string }, data: IListQuery) => {

        const { limit, page, select, order, type } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}${type ? '&type='+type : ''}`;
        let userId: string = param.id;

        let payload = {
            settlementId: param.settlementId ? param.settlementId : '',
            code: param.code ? param.code : ''
        }

        setLoading()
        await Axios.post(`${process.env.REACT_APP_VACE_URL}/businesses/settlement-transactions/${userId}?${q}`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get transactions ${err}`)

                }

            })

    }

    const getInvoices = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/invoices/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_INVOICES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business invoices ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business invoices ${err}`)

                }

            })

    }

    const getInvoice = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_INVOICE,
                    payload: resp.data.data
                });

                setInvoiceItems(resp.data.data.items);

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get an invoice ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get an invoice ${err}`)

                }

            })

    }

    const getInvoiceByCode = async (code: string, preview: boolean) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/by-code/${code}`, storage.getConfigWithBearer())
            .then((resp) => {

                if (!resp.data.data.payment) {

                    if (preview === false) {

                        setResponse({
                            data: null,
                            message: 'This invoice is not properly confgured. No payment link attached.',
                            status: 400,
                            errors: [],
                            error: true
                        })

                    } else {

                        dispatch({
                            type: GET_INVOICE,
                            payload: resp.data.data
                        });

                        setInvoiceItems(resp.data.data.items);

                    }


                } else {

                    dispatch({
                        type: GET_INVOICE,
                        payload: resp.data.data
                    });

                    setInvoiceItems(resp.data.data.items);
                }





            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This invoice is currently deactivated. Contact business to enable you to view details.'
                } if (status === 404) {
                    message = 'This invoice does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice ${err}`)

                }

                unsetLoading()

            })

    }

    const getInvoiceTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice transactions ${err}`)

                }

            })

    }

    const setPagination = (data: any) => {

        dispatch({
            type: SET_PAGINATION,
            payload: data
        })

    }

    const setSearch = ({ error, message, data, list }: Partial<ISearchProps>) => {
        dispatch({
            type: SET_SEARCH,
            payload: { error, message, data, list }
        })
    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const unsetLoading = () => {
        dispatch({
            type: UNSET_LOADING,
        })
    }

    const setAPIDone = (flag: boolean) => {
        dispatch({
            type: SET_APIDONE,
            payload: flag
        })
    }

    const setWallet = (data: any) => {
        dispatch({
            type: GET_WALLET,
            payload: data
        })
    }

    const setPaymentLink = (data: any) => {
        dispatch({
            type: GET_PAYMENT_LINK,
            payload: data
        })
    }

    const setInvoice = (data: any) => {
        dispatch({
            type: GET_INVOICE,
            payload: data
        })
    }

    const setSplits = (data: Array<IProductSplit>) => {
        dispatch({
            type: SET_SPLITS,
            payload: data
        })
    }

    const setInvoiceItems = (data: Array<IInvoiceItem>) => {
        dispatch({
            type: SET_INVOICE_ITEMS,
            payload: data
        })
    }

    const setResponse = (data: Partial<IResponse>) => {
        dispatch({
            type: SET_RESPONSE,
            payload: data
        })
    }

    const setBank = (data: any) => {
        dispatch({
            type: SET_BANK,
            payload: data
        })
    }

    const setDataPlans = (data: Array<any>) => {
        dispatch({
            type: GET_MOBILE_PLANS,
            payload: data
        })
    }

    const setBills = (data: Array<any>) => {
        dispatch({
            type: GET_BILL_CATEGORIES,
            payload: data
        })
    }

    const setAccount = (data: any) => {
        dispatch({
            type: GET_ACCOUNT,
            payload: data
        })
    }

    const setGraphData = (data: SetGraphDataDTO) => {

        const { overview } = data;

        let wallet = overview.wallet.graph;
        let yearGraph = setYearChartData({ income: wallet.income, transactions: wallet.transactions, type: 'yearly' });

        dispatch({
            type: GET_VACE_GRAPH,
            payload: {
                wallet: wallet,
                yearChart: yearGraph.yearChart,
                series: yearGraph.series,
                labels: yearGraph.labels,
                pieData: []
            }
        })


    }

    const setYearChartData = (data: SetYearChartDTO): { series: Array<any>, labels: Array<string>, yearChart: any } => {

        let { transactions, income, type } = data;
        let result: { series: Array<any>, labels: Array<string>, yearChart: any } = { series: [], labels: [], yearChart: {} }

        let labels: Array<any> = [], incomeData: Array<any> = [],
            txnData: Array<any> = [], source: Array<any> = [], series: Array<any> = [];

        if (type === 'yearly') {

            // process labels and income
            for (let i = 0; i < income.length; i++) {

                let item = income[i];

                labels.push(body.captialize(item.label));
                incomeData.push(item.total)

            }

            // process transactions
            for (let i = 0; i < transactions.length; i++) {

                let item = transactions[i];
                txnData.push(item.total)

            }

            // capture source
            source = [
                {
                    name: 'Collections',
                    data: incomeData
                },
            ]

            // capture series
            source.forEach((x) => {

                series.push({
                    name: x.name,
                    stack: "Total",
                    data: x.data,
                    type: 'line',
                    colorBy: 'data',
                    lineStyle: {
                        color: x.name === 'income' ? '#3FCD78' : '#3990E0'
                    },
                })

            });

            // save details;
            result.series = series;
            result.labels = labels;
            result.yearChart = { labels, source }

        }

        return result;

    }

    const setPieData = (data: SetPieDataDTO) => {

        let { original, paymentLinks, products, transactions } = data;

        let pieEmpty = false;
        let pieChart = original.pieData;

        pieChart = [
            { name: 'Products', value: products },
            { name: "Payment Links", value: paymentLinks },
            { name: "Transactions", value: transactions }
        ]

        if (products <= 0 && paymentLinks <= 0 && transactions <= 0) {
            pieEmpty = true;
        }

        original.pieData = pieChart;
        original.pieEmpty = pieEmpty;

        dispatch({
            type: GET_VACE_GRAPH,
            payload: original
        })

    }

    const setPaymentMethods = (data: Array<ICheckoutOption>) => {

        dispatch({
            type: SET_PAYMENT_METHODS,
            payload: data
        })

    }

    const setFilterOptions = (data: IFilterOptions) => {

        dispatch({
            type: SET_FILTER_OPTIONS,
            payload: data
        })

    }

    const setAnalytics = (data: any) => {

        dispatch({
            type: SET_ANALYTICS,
            payload: data
        })

    }

    const setCurrency = (data: string) => {

        dispatch({
            type: SET_CURRENCY,
            payload: data
        })

    }

    return <VaceContext.Provider
        value={{
            overview: state.overview,
            analytics: state.analytics,
            settings: state.settings,
            graph: state.graph,
            business: state.business,
            wallet: state.wallet,
            webhook: state.webhook,
            accounts: state.accounts,
            account: state.account,
            chargebacks: state.chargebacks,
            chargeback: state.chargeback,
            refunds: state.refunds,
            refund: state.refund,
            invoices: state.invoices,
            invoice: state.invoice,
            invoiceItems: state.invoiceItems,
            paymentLinks: state.paymentLinks,
            paymentLink: state.paymentLink,
            subaccounts: state.subaccounts,
            subaccount: state.subaccount,
            settlements: state.settlements,
            settlement: state.settlement,
            splits: state.splits,
            billers: state.billers,
            biller: state.biller,
            currency: state.currency,
            dataPlans: state.dataPlans,
            bills: state.bills,
            billProducts: state.billProducts,
            beneficiaries: state.beneficiaries,
            beneficiary: state.beneficiary,
            banks: state.banks,
            bank: state.bank,
            products: state.products,
            product: state.product,
            transactions: state.transactions,
            transaction: state.transaction,
            paymentMethods: state.paymentMethods,
            filter: state.filter,
            search: state.search,
            total: state.total,
            count: state.count,
            pagination: state.pagination,
            progress: state.progress,
            loading: state.loading,
            apiDone: state.apiDone,
            response: state.response,
            getOverview,
            getWebhookData,
            getGraphData,
            getBusiness,
            getProducts,
            getProduct,
            getProductTransactions,
            getPaymentLinks,
            getPaymentLink,
            getPaymentLinkByLabel,
            getSubaccounts,
            getSubaccount,
            getSubaccountByCode,
            getSettlements,
            getSettlement,
            getSettlementAnalytics,
            getSettlementTransactions,
            getInvoices,
            getInvoice,
            getInvoiceByCode,
            getInvoiceTransactions,
            getWallet,
            getAccounts,
            getTransactions,
            getTransaction,
            verifyTransaction,
            getBeneficiaries,
            getBanks,
            getWalletTransactions,
            getLinkTransactions,
            setSearch,
            setWallet,
            getBillers,
            getBillCategories,
            getBillProducts,
            getMobileDataPlans,
            setSplits,
            setPaymentLink,
            setLoading,
            setBank,
            setResponse,
            setDataPlans,
            setYearChartData,
            setGraphData,
            setPieData,
            setAPIDone,
            setInvoiceItems,
            setInvoice,
            setBills,
            setPaymentMethods,
            setFilterOptions,
            setAnalytics,
            setPagination,
            setAccount,
            setCurrency,
            unsetLoading,
        }}
    >
        {props.children}

    </VaceContext.Provider>

}

export default VaceState