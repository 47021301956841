import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';

import Alert from '../../partials/Alert';
import storage from '../../../helpers/storage';
import UserContext from '../../../context/user/userContext';
import { IAddMoneyModal, IUserContext, IVaceContext } from '../../../utils/types';
import Placeholder from '../../partials/Placeholder';
import Toast from '../../partials/Toast';
import VaceContext from '../../../context/vace/vaceContext';
import EmptyBox from '../../partials/Empty';
import body from '../../../helpers/body';

const AccountDetailsModal = ({ isShow, closeModal, modalTitle, flattened, stretch, slim, type, data }: Partial<IAddMoneyModal>) => {

  const userContext = useContext<IUserContext>(UserContext)
  const vaceContext = useContext<IVaceContext>(VaceContext)

  const [reset, setReset] = useState({
    email: ''
  })
  const [step, setStep] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [active, setActive] = useState<boolean>(false);
  const [check, setCheck] = useState<number>(0);
  const [rm, setRm] = useState<boolean>(false)
  const [alert, setAlert] = useState({
    type: '',
    show: false,
    message: ''
  });

  const [sendData, setSendData] = useState({
    phoneNumber: '',
    phoneCode: '',
    pin: ''
  })


  const [toast, setToast] = useState({
    type: 'success',
    show: false,
    message: '',
    title: '',
    position: 'top-right'
  })

  useEffect(() => {
    setStep(0);
  }, [])

  const closeX = (e: any = null) => {
    if (e) e.preventDefault();
    closeModal();

    setTimeout(() => {
      setStep(0)
    }, 400)
  }

  const onSelectMethod = (e: any, text: string, data: string) => {

    if (e) e.preventDefault()

    setAlert({ ...alert, type: 'success', show: true, message: `${text} copied to clipboard` })
    setTimeout(() => {
      setAlert({ ...alert, show: false })
    }, 3000)

    storage.copyCode(data)

  }

  return (
    <>

      <Modal show={isShow}
        onHide={() => closeX()}
        size="sm"
        fade={false}
        keyboard={false}
        aria-labelledby="medium-modal"
        centered
        className={`custom-modal send ${slim ? slim : ''} ${stretch ? 'stretched' : ''} ${flattened ? 'flat' : ''} lg`}
      >

        <Modal.Body className=''>


          <div className="d-flex">

            <div className="dm--dbx ui-full-bg-norm" style={{ backgroundImage: 'url("../../../images/assets/bg@auth01.jpg")' }}>
              <div className="dm--d">
                <div>
                  {/* <img src="../../../images/assets/i" alt="icon" /> */}
                </div>
              </div>
            </div>

            <div className="dm--body">

              <div className={`d-flex align-items-center mrgb0`}>

                <div className={`d-flex align-items-center`}>

                  <h2 className={`brand-purple mrgb0 font-aeonik-medium fs-16 fs-sm-13`}>
                    {modalTitle}
                  </h2>

                </div>

                <div className="ml-auto">
                  <Link to="" onClick={(e) => closeX(e)} className="link-round sm ui-icon-animate bg-terralpurple">
                    <span className="fe fe-x fs-20 fs-sm-12 brand-purple"></span>
                  </Link>
                </div>

              </div>

              <div className="dm--ct mrgt1-mid sm-mrgt1">

                <div className="ui-line-height">

                  <p className="mrgb0 font-aeonik fs-14 brand-textmain">Transfers of funds to this bank account number automatically reflects on your Terraswitch wallet. Recieve payments to this account from all banks.</p>


                  <div className={`${!vaceContext.account ? 'mrgt0 mrgb' : 'mrgt2 mrgb1-mid'} `}>
                  <Alert type={alert.type} show={alert.show} message={alert.message} />

                    {
                      vaceContext.loading &&
                      <>
                        <Placeholder
                          height='17px'
                          width="250px"
                          radius={'10px'}
                          bgColor='#eceaf1'
                          animate={true}
                        />
                      </>
                    }

                    {
                      !vaceContext.loading &&
                      <>
                        <div className="tr-list">

                          {
                            vaceContext.account && vaceContext.account.bank &&
                            <>
                              <div className="tr-box mrgb1">

                                <div className='tr-card sm'>

                                  <div className='img-card'>
                                    <span className={`ts-icon terra-bank`}>
                                      <i className='path1 fs-17'></i>
                                      <i className='path2 fs-17'></i>
                                    </span>
                                  </div>

                                  <div className='ml-4 ui-line-height-small'>
                                    <p className="mrgb0 font-aeonik brand-textmain  fs-13">Bank Name</p>
                                    <p className="mrgb0">
                                      <span className='font-aeonik brand-purple-milk fs-14'>{vaceContext.account.bank.legalName.toUpperCase()}</span>
                                    </p>
                                  </div>

                                  <Link to='' onClick={(e) => onSelectMethod(e, 'Bank name', vaceContext.account.bank.legalName)} className='custom-badge mpurple smd fs-11 font-aeonik ml-auto'>Copy</Link>

                                </div>

                              </div>

                              <div className="tr-box mrgb1">

                                <div className='tr-card sm'>

                                  <div className='img-card'>
                                    <span className={`ts-icon terra-bank`}>
                                      <i className='path1 fs-17'></i>
                                      <i className='path2 fs-17'></i>
                                    </span>
                                  </div>

                                  <div className='ml-4 ui-line-height-small'>
                                    <p className="mrgb0 font-aeonik brand-textmain  fs-13">Account Number</p>
                                    <p className="mrgb0">
                                      <span className='font-aeonik brand-purple-milk fs-14'>{vaceContext.account.accountNo}</span>
                                    </p>
                                  </div>

                                  <Link to='' onClick={(e) => onSelectMethod(e, 'Account number', vaceContext.account.accountNo)} className='custom-badge mpurple smd fs-11 font-aeonik ml-auto'>Copy</Link>

                                </div>

                              </div>

                              <div className="tr-box mrgb1">

                                <div className='tr-card sm'>

                                  <div className='img-card'>
                                    <span className={`ts-icon terra-bank`}>
                                      <i className='path1 fs-17'></i>
                                      <i className='path2 fs-17'></i>
                                    </span>
                                  </div>

                                  <div className='ml-4 ui-line-height-small'>
                                    <p className="mrgb0 font-aeonik brand-textmain  fs-13">Account Name</p>
                                    <p className="mrgb0">
                                      <span className='font-aeonik brand-purple-milk fs-14'>{vaceContext.account.accountName}</span>
                                    </p>
                                  </div>

                                  <Link to='' onClick={(e) => onSelectMethod(e, 'Account name', vaceContext.account.accountName)} className='custom-badge mpurple smd fs-11 font-aeonik ml-auto'>Copy</Link>

                                </div>

                              </div>

                            </>
                          }



                        </div>
                        {
                          !vaceContext.account &&
                          <>

                            <EmptyBox bgColor='#F7F3FF' size='xxsm'>

                              <div className='font-aeonik fs-14 brand-textmain mrgb1'>Your Terraswitch account has not been activated yet</div>


                            </EmptyBox>

                          </>
                        }
                      </>


                    }



                  </div>
                </div>

                <div onClick={() => closeModal()} className='w-100 d-flex justify-content-end mrgb1'>
                  <Link to="" className='btn sm stretch-md onwhite bg-purple'>
                    <span className='font-aeonik-bold fs-14'>Close</span>
                  </Link>
                </div>

              </div>

            </div>

          </div>

        </Modal.Body>

      </Modal>

    </>
  )

}

export default AccountDetailsModal