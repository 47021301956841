import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import loader from './loader'
import Axios from 'axios';
import storage from './storage';
import Cookies from 'universal-cookie';
import SocketContext from '../context/socket/socketContext';
import { ISocketContext, IVaceContext } from '../utils/types';
import { UserVerifyTxnDTO } from '../dtos/transaction.dto';
import VaceContext from '../context/vace/vaceContext';
import WalletService from '../services/wallet.service';
import { StatusType } from '../utils/enums';
import ComplianceModal from '../pages/dashboard/kyb/ComplianceModal';

const logout = async () => {
    const cookie = new Cookies();

    storage.clearAuth();
    localStorage.clear();

    // remove cookies
    cookie.remove('token');
    cookie.remove('userType');

    await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`,{}, storage.getConfig());
}


export function useNetworkDetect(){

    
    const toggleNetwork = (e: any) => {
        loader.popNetwork()
    }

    useEffect(() => {

        window.addEventListener(`offline`, toggleNetwork, false);
        window.addEventListener(`online`, () => { }, false);

    }, [])

}

export const usePageRedirect = (types: Array<string>) => {

    const navigate = useNavigate();
    const cookie = new Cookies();

    const ut = cookie.get("userType");

    useEffect(() => {
        fireRedirect()
    }, [])

    const fireRedirect = () => {

        if(!storage.checkToken() && !storage.checkUserID()){
            navigate('/login');
            logout()
        }else if(ut === '' || ut === undefined || ut === null){
            navigate('/login');
            logout()
        }else if(types.includes(ut) === false){
            navigate('/login');
            logout()
        }

    }

}

export const useNotificationSocket = (conn: boolean, callback: any) => {

    const socketContext = useContext<ISocketContext>(SocketContext);

    useEffect(() => {

        socketContext.socket.connect()

        socketContext.socket.on('connect', () => {

            console.log(socketContext.socket.id);
            // trigger callback function
            callback(!conn)

        });

        fireDefault();

    }, [conn])

    const fireDefault = async () => {
        if(storage.getUserID()){
            socketContext.socket.emit('get-notification', { userId: storage.getUserID() })
        }
    }

}

export const useWalletSocket = (conn: boolean, callback: any) => {

    const socketContext = useContext<ISocketContext>(SocketContext);

    useEffect(() => {

        socketContext.vsocket.connect()

        socketContext.vsocket.on('connect', () => {

            console.log('v-scoket',socketContext.vsocket.id);
            // trigger callback function
            callback(!conn)

        });

        fireDefault();

    }, [conn])

    const fireDefault = async () => {
        if(storage.getUserID()){
            socketContext.vsocket.emit('get-wallet', { businessId: storage.getUserID() })
        }
    }

}

export const useVerifyTransaction = (data: UserVerifyTxnDTO) => {

    const { conn, callback, reference } = data;
    const socketContext = useContext<ISocketContext>(SocketContext);

    useEffect(() => {

        socketContext.vsocket.connect()

        socketContext.vsocket.on('connect', () => {

            console.log('v-scoket',socketContext.vsocket.id);
            // trigger callback function
            callback(!conn)

        });

        fireDefault();

    }, [conn])

    const fireDefault = () => {
        if(reference){
            socketContext.vsocket.emit('verify-transaction', { reference })
        }
    }

}

export const useAccountCheck = (toggle:() => void) => {

    const vaceContext = useContext<IVaceContext>(VaceContext);

    useEffect(() => {

        if(vaceContext.settings.account && vaceContext.settings.account === StatusType.INACTIVE){
            toggle()
        }

    }, [vaceContext.settings])

}