import {
    GET_LOGGEDIN_USER,
    GET_USER,
    SET_LOADING,
    SET_IS_ADMIN,
    SET_IS_SUPER,
    SET_USERTYPE,
    UNSET_LOADING,
    SET_SIDEBAR,
    SET_USER,
    SET_NOTIFICATIONS,
    GET_KYC_DATA,
    GET_KYB_DATA,
    GET_VERIFICATION_DATA,
    GET_APIKEY,
    GET_DEVICES,
} from '../types';


const reducer = (state: any, action: any) => {

    switch (action.type) {

        case GET_LOGGEDIN_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case GET_USER:
            return {
                ...state,
                getuser: action.payload,
                loading: false
            }
        case GET_APIKEY:
            return {
                ...state,
                apikey: action.payload,
                loading: false
            }
        case GET_KYC_DATA:
            return {
                ...state,
                KYCData: action.payload,
                loading: false
            }
        case GET_KYB_DATA:
            return {
                ...state,
                KYBData: action.payload,
                loading: false
            }
        case GET_VERIFICATION_DATA:
            return {
                ...state,
                verification: action.payload,
                loading: false
            }
        case GET_DEVICES:
            return {
                ...state,
                devices: action.payload,
                loading: false
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case SET_USERTYPE:
            return {
                ...state,
                userType: action.payload,
                loading: false
            }
        case SET_SIDEBAR:
            return {
                ...state,
                sidebar: action.payload,
                loading: false
            }
        case SET_IS_SUPER:
            return {
                ...state,
                isSuper: action.payload,
                loading: false
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
                loading: false
            }
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload,
                loading: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

        case UNSET_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }

}

export default reducer;