import {
    GET_BANKS,
    GET_LOCATIONS,
    GET_COUNTRIES,
    GET_COUNTRY,
    GET_IP_ADDRESS,
    SET_LOADING,
    GET_TRANSACTIONS,
    GET_TRANSACTION,
    GET_SETTLEMENTS,
    GET_ARCHIVED,
    GET_NETWORKS,
    GET_PAYMENTS
} from '../types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {

    switch (action.type) {

        case GET_BANKS:
            return {
                ...state,
                banks: action.payload,
                loading: false
            }

        case GET_NETWORKS:
            return {
                ...state,
                networks: action.payload,
                loading: false
            }
        case GET_LOCATIONS:
            return {
                ...state,
                locations: action.payload,
                loading: false
            }
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
                loading: false
            }
        case GET_COUNTRY:
            return {
                ...state,
                country: action.payload,
                loading: false
            }
        case GET_IP_ADDRESS:
            return {
                ...state,
                ipData: action.payload,
                loading: false
            }


        case GET_PAYMENTS:
            return {
                ...state,
                payments: action.payload,
                loading: false
            }

        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
                loading: false
            }

        case GET_TRANSACTION:
            return {
                ...state,
                transaction: action.payload,
                loading: false
            }

        case GET_SETTLEMENTS:
            return {
                ...state,
                settlements: action.payload,
                loading: false
            }

        case GET_ARCHIVED:
            return {
                ...state,
                archived: action.payload,
                loading: false
            }

        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }

}