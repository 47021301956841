import React, { useEffect, useState, useContext } from "react"
import { INotificationPanel, IUserContext, IVaceContext } from "../../utils/types";
import { Link } from 'react-router-dom'
import VaceContext from "../../context/vace/vaceContext";
import UserContext from "../../context/user/userContext";
import moment from 'moment'

const NotificationPanel = ({ display, show, animate, data, close, size, type, markAsRead, loading }: Partial<INotificationPanel>) => {

    const userContext = useContext<IUserContext>(UserContext)
    const vaceContext = useContext<IVaceContext>(VaceContext)

    useEffect(() => {

    }, [])

    const closeX = (e: any): void => {
        if (e) e.preventDefault();

        close(e, 'close');

        setTimeout(() => {
        }, 1000)

    }

    const fireMarkAsRead = (e: any, id: any) => {
        if(e){
            e.preventDefault()
        }

        if(markAsRead){
            markAsRead(e, id);
        }
        
    }

    return (
        <>

            <div className={`panel-box ${show ? '' : 'ui-hide'}`}>

                <div className={`display ${size} ${animate ? 'animate-box' : ''}`}>

                    {/* Panel head */}
                    <div className='d-flex align-items-center'>

                        <h2 className={`brand-purple mrgb0 font-aeonik-medium fs-16 fs-sm-13`}>Notifications</h2>

                        <div className='ml-auto'>
                            <Link to="" onClick={(e) => closeX(e)} className="link-round sm ui-icon-animate bg-purple-bright">
                                <span className="fe fe-x fs-20 fs-sm-12 brand-purple"></span>
                            </Link>
                        </div>

                    </div>
                    {/* End Panel head */}

                    {/* Panel box body */}
                    <div className='panel-box-body'>

                        <div className='ui-separate-small'></div>

                        <div className={`notification-list ${loading ? 'disabled-lt' : ''}`}>

                            {
                                userContext.notifications.length > 0 &&
                                userContext.notifications.map((data: any, index: number) => 
                                    <>
                                        <div className="notif-item">
                                            <p className={`brand-textmain mrgb font-aeonik-medium fs-13 fs-sm-13`}>{ data.title }</p>
                                            <div className="brand-purple-dark mrgb0 font-aeonik ui-line-height fs-13 fs-sm-13 wp-100 mrgb">{ data.body }</div>
                                            <div className="item-head d-flex align-items-center mrgb">
                                                <span className={`mrgb0 font-aeonik brand-purple-dark fs-13 fs-sm-13`}>{ moment(data.createdAt).format('Do MMM, YYYY') }</span>
                                                <Link onClick={(e) => fireMarkAsRead(e, data._id)} to={''} className={`brand-purple mrgb0 font-aeonik-medium fs-13 fs-sm-13 ml-auto`}>Mark as read</Link>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                    </div>
                    {/* End Panel box body */}

                </div>

            </div>

        </>
    )


};

export default NotificationPanel;
