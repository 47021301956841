import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Axios from 'axios';
import storage from '../../helpers/storage'
import loader from '../../helpers/loader'

import UserContext from './userContext';
import UserReducer from './userReducer';

import {
    GET_LOGGEDIN_USER,
    SET_USERTYPE,
    SET_IS_ADMIN,
    SET_IS_SUPER,
    SET_LOADING,
    UNSET_LOADING,
    SET_SIDEBAR,
    SET_USER,
    GET_BUSINESS,
    SET_NOTIFICATIONS,
    GET_KYB_DATA,
    GET_KYC_DATA,
    GET_VERIFICATION_DATA,
    GET_APIKEY,
    GET_DEVICES
} from '../types'
import { IListQuery } from '../../utils/types';

const UserState = (props: any) => {

    const cookie = new Cookies();

    const exp = new Date(
        Date.now() + 70 * 24 * 60 * 60 * 1000
    )

    const navigate = useNavigate()
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        user: {},
        userType: '',
        notifications: [],
        devices: [],
        business: {},
        KYBData: {},
        KYCData: {},
        apikey: {},
        verification: {},
        isSuper: false,
        sidebar: {},
        isAdmin: false,
        loading: false,
        search: {
            error: false,
            message: '',
            data: []
        },
    }

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        localStorage.clear();
        cookie.remove('token');
        cookie.remove('userType');

        navigate('/login');
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`,{}, storage.getConfig());
    }

    const getUser = async (id: string) => {

        setLoading()
        
        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/user/${id}`, storage.getConfigWithBearer())
        .then((resp) => {

            dispatch({
                type: GET_LOGGEDIN_USER,
                payload: resp.data.data
            });

            dispatch({
                type: GET_VERIFICATION_DATA,
                payload: resp.data.data.verification
            });
            
            cookie.set("userType", resp.data.data.userType , {
                path: '/',
                expires: exp
            });

            cookie.set("businessType", resp.data.data.businessType , {
                path: '/',
                expires: exp
            });

        }).catch((err: any) => {

            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get logged in user ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get logged in user ${err}`)

            }
            
        })

    }

    const getAPIKey = async (id?: string) => {

        let userId = id ? id : storage.getUserID();

        setLoading();
        
        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users/apikey/${userId}`, storage.getConfigWithBearer())
        .then((resp) => {

            dispatch({
                type: GET_APIKEY,
                payload: resp.data.data
            });

        }).catch((err: any) => {

            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get user api key ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get user api key ${err}`)

            }
            
        })

    }

    const getUserDevices = async (id:string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${ order ? order : 'desc' }`;

        let userId = id ? id : storage.getUserID();

        setLoading();
        
        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users/devices/${userId}`, storage.getConfigWithBearer())
        .then((resp) => {

            dispatch({
                type: GET_DEVICES,
                payload: resp.data.data
            });

        }).catch((err: any) => {

            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get user devices ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get user devices ${err}`)

            }
            
        })

    }

    const getCompliance = async (data: { id: string, type: 'kyb' | 'kyc' }) => {

        const { id, type } = data

        setLoading()
        
        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users/${type}/${id}`, storage.getConfigWithBearer())
        .then((resp) => {

            if(type === 'kyb'){

                dispatch({
                    type: GET_KYB_DATA,
                    payload: resp.data.data.kyb
                });

                dispatch({
                    type: GET_VERIFICATION_DATA,
                    payload: resp.data.data.verification
                });

            }

            if(type === 'kyc'){
                dispatch({
                    type: GET_KYC_DATA,
                    payload: resp.data.data.kyc
                });
                dispatch({
                    type: GET_VERIFICATION_DATA,
                    payload: resp.data.data.verification
                });
            }

        }).catch((err: any) => {

            if(err && err.response && err.response.data && err.response.data.status === 401){

                logout();

            }else if(err && err.response && err.response.data){

                console.log(`Error! Could not get compliance data ${err.response.data}`)

            }else if(err && err.toString() === 'Error: Network Error'){

                loader.popNetwork();

            }else if(err){

                console.log(`Error! Could not get compliance data ${err}`)

            }
            
        })

    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const unsetLoading = () => {
        dispatch({
            type: UNSET_LOADING,
        })
    }

    const setUserType = (n: string) => {

        dispatch({
            type: SET_USERTYPE,
            payload: n
        })

        if(n === 'superadmin'){
            dispatch({
                type: SET_IS_SUPER,
                payload: true
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: false
            })
        }else if(n === 'admin'){
            dispatch({
                type: SET_IS_SUPER,
                payload: false
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: true
            })
        }else {
            dispatch({
                type: SET_IS_SUPER,
                payload: false
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: false
            })
        }
    }

    const getUserType = () => {
        const ut = cookie.get('userType');
        return ut ?  ut.toString() : '';
    }

    const isLoggedIn = (): boolean => {

        let flag = false;

        const ut = cookie.get('userType').toString();
        const tk = cookie.get('token').toString();

        if(storage.getUserID() && storage.getToken() && tk && ut){
            flag = true;
        }

        return flag

    }

    const setSidebar = (a: boolean, l: string) => {
        dispatch({
            type: SET_SIDEBAR,
            payload: { active: a, label: l }
        })
    }

    const setUser = (data: any) => {
        dispatch({
            type: SET_USER,
            payload: data
        })
    }

    const setNotifications = (data: any) => {
        dispatch({
            type: SET_NOTIFICATIONS,
            payload: data
        })
    }

    const setVerification = (data: any) => {
        dispatch({
            type: GET_VERIFICATION_DATA,
            payload: data
        })
    }

    return <UserContext.Provider
        value={{
            notifications: state.notifications,
            devices: state.devices,
            apikey: state.apikey,
            user: state.user,
            KYBData: state.KYBData,
            KYCData: state.KYCData,
            verification: state.verification,
            business: state.business,
            sidebar: state.sidebar,
            userType: state.userType,
            isSuper: state.isSuper,
            isAdmin: state.isAdmin,
            loading: state.loading,
            getUser,
            getAPIKey,
            getUserDevices,
            setUserType,
            setSidebar,
            getUserType,
            getCompliance,
            setNotifications,
            setUser,
            setVerification,
            unsetLoading,
            isLoggedIn
        }}
    >
        {props.children}

    </UserContext.Provider>
  
}

export default UserState